.new-feature-badge {
    color: inherit;
}

.blink_me {
    animation: blinker 1.5s linear infinite;
    animation-iteration-count: 4;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}